export class PubSub<T extends Function, V> {
  private listeners: T[] = [];

  addListener(listener: T) {
    this.listeners.push(listener);
  }

  notifyListeners(value: V) {
    this.listeners.forEach((listener) => listener(value));
  }
}
