import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import {
  MembersAreaHostEvent,
  MembersAreaWidgetPlugin,
  MembersAreaWidgetPluginService,
  MembersIdentifiers,
} from '../../lib';
import { Experiment } from '../../types';
import { createStore } from '../../store';
import {
  getControllerProps,
  getInitialData,
  getSEODataWithProfileURL,
} from './controller-utils';
import {
  createSettingsListener,
  registerControllerListeners,
  registerUserListeners,
  registerWidgetPluginHostListeners,
} from './controller-listeners';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig: { wixCodeApi, setProps },
}) => {
  const settingsListener = createSettingsListener();
  const widgetPluginService = new MembersAreaWidgetPluginService();

  return {
    async pageReady() {
      const { initialState, seoData } = await getInitialData(
        flowAPI,
        widgetPluginService,
      );
      const store = createStore(
        { flowAPI, wixCodeApi, widgetPluginService },
        initialState,
      );

      store.subscribe(() => setProps(store.getState()));
      setProps(getControllerProps(flowAPI, store));
      registerUserListeners(flowAPI, store);
      registerControllerListeners(flowAPI, settingsListener, store);
      registerWidgetPluginHostListeners(widgetPluginService, store);

      const isRenderSEOTagsEnabled = flowAPI.experiments.enabled(
        Experiment.RenderSEOTags,
      );

      if (flowAPI.environment.isViewer && isRenderSEOTagsEnabled && seoData) {
        const itemData = await getSEODataWithProfileURL(wixCodeApi, seoData);
        wixCodeApi.seo.renderSEOTags({
          itemType: 'MEMBERS_AREA_PROFILE', // Take it from @wix/advanced-seo-utils when it's available
          itemData,
        });
      }
    },
    updateConfig(_, { publicData }) {
      settingsListener.notify(publicData.COMPONENT || {});
    },
    exports: (): MembersAreaWidgetPlugin => {
      return {
        set isWidgetPlugin(value: boolean) {
          widgetPluginService.setIsWidgetPlugin(value);
        },
        set members(value: MembersIdentifiers) {
          widgetPluginService.setMembers(value);
        },
        onMembersAreaEvent(event: MembersAreaHostEvent) {
          widgetPluginService.notifyMembersAreaEventListeners(event);
        },
      };
    },
  };
};

export default createController;
