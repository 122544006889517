import {
  IMembersAreaWidgetPluginService,
  MembersAreaHostEvent,
  MembersAreaHostEventHandler,
  MembersIdentifiers,
  Nullable,
} from './types';
import { PubSub } from './pub-sub';

type MembersListener = (members: MembersIdentifiers) => void;

export class MembersAreaWidgetPluginService
  implements IMembersAreaWidgetPluginService
{
  private isWidgetPlugin = false;
  private members: Nullable<MembersIdentifiers> = null;
  private membersWereSet = false;
  private membersPubSub = new PubSub<MembersListener, MembersIdentifiers>();
  private eventPubSub = new PubSub<
    MembersAreaHostEventHandler,
    MembersAreaHostEvent
  >();

  async getMembers(): Promise<Nullable<MembersIdentifiers>> {
    if (this.membersWereSet || !this.isWidgetPlugin) {
      return this.members;
    }

    return new Promise((resolve) => this.membersPubSub.addListener(resolve));
  }

  setMembers(members: MembersIdentifiers) {
    this.members = members;
    this.membersWereSet = true;
    this.membersPubSub.notifyListeners(members);
  }

  onMembersAreaEvent(listener: MembersAreaHostEventHandler) {
    this.eventPubSub.addListener(listener);
  }

  notifyMembersAreaEventListeners(event: MembersAreaHostEvent) {
    this.eventPubSub.notifyListeners(event);
  }

  getIsWidgetPlugin() {
    return this.isWidgetPlugin;
  }

  setIsWidgetPlugin(isWidgetPlugin: boolean) {
    this.isWidgetPlugin = isWidgetPlugin;
  }
}
